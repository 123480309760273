import { Grid, Paper, Container, Stack, Button, Skeleton } from "@mui/material"
import { Fragment, useState } from "react"
import  Logo  from "../../assets/logo.png"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DialogCancel from "./DialogCancel";

const Header = (props) => {

    const {
        category,
        timer,
        urlOut
    } = props
    const [dialogCancel,setDialogCancel] = useState(false)

    return (
        <Fragment>
            <DialogCancel open={dialogCancel} onCancel={(par) => { setDialogCancel(par)}} urlOut={urlOut}/>
            <Container maxWidth="xl" className="mt-3">
                <Grid sx={{ flexGrow: 1 }} container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="stretch" spacing={2} >

                    <Grid item md={4}>
                        <Paper elevation={3} className="p-2" style={{ height: "77px" }}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <div>
                                    <Grid container spacing={3}>
                                        <Grid item xs="auto">
                                            <img 
                                                src={Logo} 
                                                style={{ width: "45px",marginLeft:"10px" }}
                                                alt="Family Education Center" />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <h6 style={{ marginTop:"13px" }}>
                                                Family Education Center <br /> Makassar
                                            </h6>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Stack>
                        </Paper>
                    </Grid>

                    <Grid item md={3} >
                        <Paper elevation={3} className="px-2 py-3" style={{ height: "77px" }}>
                          { category && <h4 className="text-center mt-1"> {category.name} </h4>  }
                          { !category && <Skeleton width={200} height={50} style={{ margin : "0px auto" }}/> }  
                        </Paper>
                    </Grid>
                    <Grid item md={5} style={{ height: "77px" }}>
                        <Paper elevation={3} className="py-2 px-3 d-flex justify-content-between align-items-center">
                            <div>
                                <span style={{ fontSize: "13px" }}>Sisa Waktu</span>
                                <h4>{  timer !== 0 && `${timer?.h}:${timer?.m}:${timer?.s}`} { timer === 0 && "00:00:00"}</h4>
                            </div>
                            <div>
                                <Button color="error" variant="outlined" onClick={() => { setDialogCancel(true) }}>
                                    <HighlightOffIcon className="mr-1" /> Keluar
                                </Button>
                            </div>
                        </Paper>

                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    )
}

export default Header