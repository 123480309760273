import { Fragment } from "react";
import "./QuestionColumn.css"

const Question = (props) => {
    const parser = new DOMParser();
    return (
        <Fragment>
            <table className="table table-bordered text-center table-question" >

                <tr>
                    <td> {parser.parseFromString(props.questionActive.instruction.a, 'text/html').body.textContent} </td>
                    <td> {parser.parseFromString(props.questionActive.instruction.b, 'text/html').body.textContent} </td>
                    <td> {parser.parseFromString(props.questionActive.instruction.c, 'text/html').body.textContent} </td>
                    <td> {parser.parseFromString(props.questionActive.instruction.d, 'text/html').body.textContent} </td>
                </tr>

            </table>
        </Fragment>
    )
}

export default Question