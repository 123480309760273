import React, { useContext } from 'react';

import Login from './app/pages/Login/Login';
import 'react-toastify/dist/ReactToastify.css';
import "./index.css"
import { ToastContainer } from 'react-toastify';
import Category from './app/pages/category/Category';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import QuestionColumn from './app/pages/question/question-column/QuestionColumn';
import QuestionText from './app/pages/question/question-text/QuestionText';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { grey, red } from '@mui/material/colors';
import Result from './app/pages/result/Result';
import  { AuthProvider,AuthContext } from './app/context/AuthContext';

function App(props) {

  return (<Aplikasi {...props} />);
}



function PrivateRoute({ children, ...rest }) {
  let userData = useContext(AuthContext);


  if (!userData) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    console.log(userData)
    // localStorage.removeItem("dataUser");
    window.location.href = "/login"
  }

  return children;
}


const theme = createTheme({
  palette: {
    neutral: {
      main: grey[500],
      contrastText: '#fff',
    },
    error: {
      main: red[700],
      contrastText: '#fff',
    },
  },

})

const Aplikasi = (props) => {

  // const userData = localStorage.getItem("dataUser")

  // if (!userData) {
  //   localStorage.removeItem("dataUser");
  // }

  // if (userData) {
  //   let expire = new Date(Helper.dataUser['login_at']);
  //   expire.setHours(expire.getHours() +  4)

  //   if (new Date() > expire) {
  //     localStorage.removeItem("dataUser");
  //   }
  // }



  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            newestOnTop
          />
          <Routes>


            <Route exact path="/login" element={<Login />} />
            <Route path="/" element={<PrivateRoute> <Category /> </PrivateRoute>} />
            <Route path="/question-column/:id" element={<PrivateRoute> <QuestionColumn /> </PrivateRoute>} />
            <Route path="/question-text/:id" element={<PrivateRoute> <QuestionText />  </PrivateRoute>} />
            <Route path="/result/:type/:correct/:id" element={<PrivateRoute>  <Result />  </PrivateRoute>} />

          </Routes>

        </Router>
      </AuthProvider>
    </ThemeProvider>
  )
}
export default App;
