import { Grid, Paper, Container, Button } from "@mui/material";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import Header from "../Header";
import OptionText from "./OptionText";
import "react-perfect-scrollbar/dist/css/styles.css";

import CategoryService from "../../../service/CategoryService";
import { useNavigate, useParams } from "react-router";
import imgReady from "../../../assets/areyouready.jpg";
import { secondsToTime } from "../../../helper";
import NumberQuestion from "./NumberQuestion";
import { AuthContext } from "../../../context/AuthContext";

const QuestionText = () => {
  let navigate = useNavigate();
  let params = useParams();
  const [category, setCategory] = useState(false);
  const [timer, setTimer] = useState(0);
  const [questionActive, setQuestionActive] = useState(false);
  const [listQuestion, setListQuestion] = useState(false);

  const questionNumberActive = useRef(0);
  const indexQuestion = useRef();
  const timeLeft = useRef();
  const totalQuestion = useRef();
  const correctAnswer = useRef(0);
  const questionWithAnswer = useRef(0);
  const { dataUser } = useContext(AuthContext);

  const getData = (id) => {
    setCategory(false);
    CategoryService.getById({
      token: dataUser ? dataUser.token : null,
      id: params.id,
      onSuccess: (res) => {
        setCategory(res.data.result);
        timeLeft.current = res.data.result.packages[0].duration;
        setListQuestion(res.data.result.packages[0].question_texts);
      },
      onFail: (err) => {
        console.log(err);
      },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const onStart = () => {
    setQuestionActive(listQuestion[0]);
    questionNumberActive.current = listQuestion[0].number;
    startTimer();
    indexQuestion.current = 0;
    totalQuestion.current = listQuestion.at(-1).number;
  };

  const startTimer = () => {
    let id = setInterval(countDown, 1000);
    return () => clearInterval(id);
  };

  const countDown = () => {
    timeLeft.current = timeLeft.current - 1;
    setTimer(secondsToTime(timeLeft.current));

    // Check if we're at zero.
    if (timeLeft.current == 0) {
      nextPackage();
    }
  };

  const onAnswer = (e) => {
    if (e.correct) {
      if (questionActive.correct) {
        correctAnswer.current =
          parseInt(correctAnswer.current) -
          questionActive.correct +
          parseInt(e.correct);
      } else {
        correctAnswer.current =
          parseInt(correctAnswer.current) + parseInt(e.correct);
      }
    }

    if (!questionActive.answer) {
      questionWithAnswer.current = questionWithAnswer.current + 1;
    }

    setListQuestion((prevState) => {
      return prevState.map((el) => {
        // <-- map state to new array

        if (el.number == questionNumberActive.current) {
          return {
            ...el, // <-- copy element
            answer: e.answer,
            correct: e.correct, // <-- write new property
          };
        } else {
          return el;
        }
      });
    });

    if (questionWithAnswer.current >= totalQuestion.current) {
      nextPackage();
    } else {
      if (
        questionActive.number == totalQuestion.current &&
        questionWithAnswer.current != totalQuestion.current
      ) {
        let questionNotAnswer = listQuestion.filter((opt) => !opt.answer)[0];
        setQuestionActive(listQuestion[questionNotAnswer.number - 1]);
        questionNumberActive.current = questionNotAnswer.number;
      } else {
        setQuestionActive(listQuestion[questionActive.number]);
        indexQuestion.current = indexQuestion.current + 1;
        questionNumberActive.current = questionNumberActive.current + 1;
      }
    }
  };

  const onChangeQuestion = (num) => {
    setQuestionActive(listQuestion[num - 1]);
    questionNumberActive.current = num;
  };

  const nextPackage = () => {
    navigate(`/result/text/${correctAnswer.current}/${params.id}`);
  };

  return (
    <Fragment>
      <Header
        timer={timer}
        category={category}
        urlOut={`/result/text/${correctAnswer.current}/${params.id}`}
      />

      {questionActive && (
        <Container maxWidth="xl" className="mt-3">
          <Grid container spacing={3}>
            <Grid item md={3}>
              <NumberQuestion
                questions={listQuestion}
                numberActive={questionActive.number}
                onChangeQuestion={(num) => onChangeQuestion(num)}
              />
            </Grid>
            <Grid item md={9}>
              <Paper className="p-4">
                <span> Pertanyaan : </span>
                <div className="d-flex ">
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "16pt",
                      marginTop: "10px",
                      marginRight: "20px",
                    }}
                  >
                    {" "}
                    {questionActive.number}{" "}
                  </span>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "16pt",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: questionActive.instruction,
                      }}
                    ></div>
                  </p>
                </div>
                <OptionText
                  choices={questionActive}
                  onAnswer={(e) => {
                    onAnswer(e);
                  }}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      )}
      {!questionActive && category && (
        <Container maxWidth="xl" className="mt-3">
          {" "}
          <Paper
            className="p-5 d-flex flex-column  align-items-center"
            style={{ height: "650px" }}
          >
            <img src={imgReady} />{" "}
            <Button
              variant="contained"
              color="success"
              className="mt-5"
              style={{ width: "400px" }}
              onClick={onStart}
            >
              MULAI
            </Button>
          </Paper>{" "}
        </Container>
      )}
    </Fragment>
  );
};

export default QuestionText;
