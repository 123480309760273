import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid, Chip } from "@mui/material";
import { useNavigate } from "react-router";
import Helper from "../../helper";

import "./Category.css";

export default function CardList(props) {
  const { data } = props;
  let navigate = useNavigate();

  const onStartQuiz = (data) => {
    let type = data.category_type_id;
    let id = data.id;
    if (type == 1) {
      navigate(`/question-column/${id}`);
    } else {
      navigate(`/question-text/${id}`);
    }
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <img
        className="image-card"
        src={
          data.image ??
          "https://images.ctfassets.net/py55p8b6ms79/1DFUYkCtHGNfiSWVg1HUSm/3dbb0f5d19c9817ade2fa9bb2ffbcf55/Design_Business_Blog_1.png"
        }
      />
      {/* <CardMedia
          component="img"
          height="150"
          image={data.image ?? "https://images.ctfassets.net/py55p8b6ms79/1DFUYkCtHGNfiSWVg1HUSm/3dbb0f5d19c9817ade2fa9bb2ffbcf55/Design_Business_Blog_1.png"}
          alt="Family Education Center"
        /> */}
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {data.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <Grid container spacing={2}>
            <Grid item xs={4} className="font-weight-bold">
              Durasi
            </Grid>
            <Grid item xs={4}>
              {Helper.getDuration(data.duration)}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4} className="font-weight-bold">
              Total Soal
            </Grid>
            <Grid item xs={4}>
              {data.question}
            </Grid>
          </Grid>
        </Typography>
      </CardContent>
      <CardActions className="d-flex justify-content-between">
        <Chip color="success" label={data.group_name} className="text-center" />
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            onStartQuiz(data);
          }}
        >
          Mulai
        </Button>
      </CardActions>
    </Card>
  );
}
