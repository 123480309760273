import { Grid, Paper, Container, Button } from "@mui/material"
import { Fragment, useEffect, useState, useRef, useContext } from "react"
import Header from "../Header"
import 'react-perfect-scrollbar/dist/css/styles.css';
import "./QuestionColumn.css"
import CategoryService from "../../../service/CategoryService";
import { useNavigate, useParams } from "react-router";
import imgReady from "../../../assets/areyouready.jpg"
import { dataUser, secondsToTime } from "../../../helper";
import Question from "./Question";
import { AuthContext } from "../../../context/AuthContext";

const QuestionColumn = () => {
    let params = useParams();
    let navigate = useNavigate();
    const parser = new DOMParser();
    const {dataUser} = useContext(AuthContext)

    const [category, setCategory] = useState(false)
    const [packageActive, setPackageActive] = useState(false)
    const [questionActive, setQuestionActive] = useState(false)
    const [listPackage, setListPackage] = useState(false)
    const [timer, setTimer] = useState(0)
    const timeLeft = useRef();
    const totalPackage = useRef();
    const indexPackage = useRef();
    const indexQuestion = useRef();
    const correctAnswer = useRef(0);
    const questionNumberActive = useRef(0)
   


    const onStart = () => {
        indexPackage.current = 0;
        totalPackage.current = (listPackage.length)
        setPackageActive(listPackage[0])
        setQuestionActive(listPackage[0].questions[0])
        questionNumberActive.current = ((listPackage[0].questions[0].number))
        startTimer()

        indexQuestion.current = listPackage[0].questions.at(-1).number

    }

    const getData = (id) => {
        setCategory(false)
        CategoryService.getById({
            token : dataUser? dataUser.token : null,
            id: params.id,
            onSuccess: (res) => {
                setCategory(res.data.result)
                setListPackage(res.data.result.packages)
                timeLeft.current = res.data.result.packages[0].duration
                
            }, onFail: (err) => {
                console.log(err)
            }
        })
    }

    useEffect(() => {
        getData();
    }, [])

    const onAnswer = (answer) => {

        if (questionActive.correct_choice == answer) {
            correctAnswer.current = (correctAnswer.current + 1);
        }

        questionNumberActive.current = questionNumberActive.current +1

        if(questionNumberActive.current > indexQuestion.current){
            nextPackage()
        }else{
            setQuestionActive(packageActive.questions.filter(question => question.number === questionNumberActive.current)[0])
        }

    }

    const startTimer = () => {
        let id = setInterval(countDown, 1000);
        return () => clearInterval(id);
    }
    const nextPackage = () => {
        indexPackage.current = indexPackage.current + 1

        if (indexPackage.current + 1 > totalPackage.current) {
            navigate(`/result/column/${correctAnswer.current}/${params.id}`)
        } else {
            setPackageActive(listPackage[indexPackage.current])
            setQuestionActive(listPackage[indexPackage.current].questions[0])
            questionNumberActive.current = (listPackage[indexPackage.current].questions[0].number)
            timeLeft.current = (listPackage[indexPackage.current].duration)
            indexQuestion.current = listPackage[indexPackage.current].questions.at(-1).number
        }
    }
    const countDown = () => {
        timeLeft.current = timeLeft.current - 1

        setTimer(secondsToTime(timeLeft.current))

        // Check if we're at zero.
        if (timeLeft.current == 0) {
            nextPackage()
        }
    }

    return (<Fragment>
        <Header category={category} packageActive={packageActive} timer={timer} urlOut={`/result/column/${correctAnswer.current}/${params.id}`}/>
        {packageActive  && <Container maxWidth="xl" className="mt-3">
            <Grid container spacing={3}  >
                <Grid item md={12}>
                    <Paper className="p-4 d-flex flex-column  align-items-center" style={{ height: "650px" }}>
                        <span className="title-instruction"> PETUNJUK UJIAN : </span>
                        <table className="table table-bordered text-center table-instruction" >
                            <tr>
                                <td> a </td>
                                <td> b </td>
                                <td> c </td>
                                <td> d </td>
                                <td> e </td>
                            </tr>
                            {packageActive && packageActive.questions.filter(question => question.number === questionNumberActive.current).map((data, i) => (
                                <tr>
                                    <td> {parser.parseFromString(data.choice.option.a, 'text/html').body.textContent} </td>
                                    <td> {parser.parseFromString(data.choice.option.b, 'text/html').body.textContent} </td>
                                    <td> {parser.parseFromString(data.choice.option.c, 'text/html').body.textContent} </td>
                                    <td> {parser.parseFromString(data.choice.option.d, 'text/html').body.textContent} </td>
                                    <td> {parser.parseFromString(data.choice.option.e, 'text/html').body.textContent} </td>
                                </tr>
                            ))}
                        </table>
                        <span className="title-question"> Soal : </span>
                        <Grid container
                            justify="center">
                            <Grid item md={3}>
                                <h3 className="mr-5 text-right py-2 mr-5">{questionNumberActive.current}</h3>
                            </Grid>
                            <Grid item md={8}>
                                <Question questionActive={questionActive}/> 
                            </Grid>
                        </Grid>
                        <table className="table table-bordered text-center table-option" >
                            <tr>
                                <td onClick={() => { onAnswer("a") }}> A </td>
                                <td onClick={() => { onAnswer("b") }}> B </td>
                                <td onClick={() => { onAnswer("c") }}> C </td>
                                <td onClick={() => { onAnswer("d") }}> D </td>
                                <td onClick={() => { onAnswer("e") }}> E </td>
                            </tr>
                        </table>
                    </Paper>
                </Grid>
            </Grid>
        </Container>}

        {!packageActive && category  && <Container maxWidth="xl" className="mt-3"> <Paper className="p-5 d-flex flex-column  align-items-center" style={{ height: "650px" }}>
            <img src={imgReady} /> <Button variant="contained" color="success" className="mt-5" style={{ width: "400px" }} onClick={onStart}>
                MULAI
            </Button>
        </Paper> </Container>}

    </Fragment >)
}

export default QuestionColumn