import Api from "./Api"


const CategoryGroupService = ({ data, onSuccess, onFail,token }) => {
    return Api.get("category-group",data,token)
        .then(res => {
            onSuccess(res)
        }).catch(err => {
            onFail(err)
        })
}

export default CategoryGroupService