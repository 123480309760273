
export const dataUser = () => {
    let dataUser = localStorage.getItem("dataUser")
    return dataUser ? JSON.parse(dataUser) : null 
}

export const getDuration = (duration) => {   
    return  (duration < 60) ? duration +" Detik" : (duration/60)+" Menit"
}

export const secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours >= 10 && hours > 0 ? hours : "0"+hours ,
      "m": minutes >= 10 && minutes > 0 ? minutes : "0"+minutes,
      "s": seconds >= 10 && seconds > 0 ? seconds : "0"+seconds
    };
    return obj;
  }

const Helper = {
    dataUser : dataUser(),
    getDuration : getDuration,
    secondsToTime : secondsToTime
}

export default Helper