import { Grid, Button, Card, Avatar } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import "./QuestionText.css";

const OptionText = (props) => {
  const { choices, onAnswer } = props;

  const [selected, setSelected] = useState([]);

  const [submitButtonActive, setSubmitButtonActive] = useState(false);
  useEffect(() => {
    setSelected([])
  },[choices])
  // onsubmit answer
  const onSubmit = () => {
    if (choices.choice_type == 1) {
      onAnswer({
        answer: selected,
        correct: choices.choice.correct_option == selected ? 1 : 0,
      });
    } else if (choices.choice_type == 2) {
      onAnswer({
        answer: selected,
        correct: getValueChoice(selected[0]),
      });
    } else if (choices.choice_type == 3) {
      let correct = 0;

      selected.map((data) => {
        if (getValueChoice(data) == 1) {
          correct = correct + 1;
        }
      });
      onAnswer({
        answer: selected,
        correct: correct == 2 ? 1 : 0,
      });
    }
    setSelected([]);
    setSubmitButtonActive(false);
  };

  const getValueChoice = (choice) => {
    switch (choice) {
      case "a":
        return choices.choice.option_a_value;
      case "b":
        return choices.choice.option_b_value;
      case "c":
        return choices.choice.option_c_value;
      case "d":
        return choices.choice.option_d_value;
      case "e":
        return choices.choice.option_e_value;
      default:
        return "salah";
    }
  };

  // on select answer
  const onSelect = (select) => {
    if (choices.choice_type == 1) {
      setSelected([select]);
      setSubmitButtonActive(true);
    } else if (choices.choice_type == 2) {
      setSelected([select]);
      setSubmitButtonActive(true);
    } else if (choices.choice_type == 3) {
      if (selected.find((item) => item == select)) {
        setSelected(selected.filter((item) => item != select));
        setSubmitButtonActive(false);
        return false;
      }

      if (selected.length == 2) {
        let oldChoice = selected.splice(selected.indexOf(0), 1);
        let newChoice = [...oldChoice, select];
        setSelected(newChoice);
        setSubmitButtonActive(true);
      } else if (selected.length == 0) {
        setSelected([select]);
      } else if (selected.length == 1) {
        setSelected([...selected, select]);
        setSubmitButtonActive(true);
      }
    }
  };

  // get color answer
  const selectedClass = (initialOption) => {
    if (selected.length > 0 && selected.find((item) => item == initialOption)) {
      return "selected";
    } else if (
      choices.answer &&
      choices.answer.find((item) => item == initialOption) &&
      !selected.length
    ) {
      return "selected";
    } else {
      return "";
    }
  };

  return (
    <Fragment>
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <Card
            variant="outlined"
            fullWidth
            className={`p-2 cursor-pointer d-flex align-items-center card-option ${selectedClass(
              "a"
            )}`}
            style={{ borderColor: "#000" }}
            onClick={() => {
              onSelect("a");
            }}
          >
            <Avatar
              sx={{ width: 30, height: 30 }}
              className="mr-2 avatar-option"
            >
              {" "}
              A{" "}
            </Avatar>
            {choices.choice.type_a == "text" ? (
              choices.choice.option_a
            ) : (
              <img
                src={choices.choice.option_a}
                style={{ height: "80px", marginLeft: "20px" }}
              />
            )}
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            variant="outlined"
            fullWidth
            className={`p-2 cursor-pointer d-flex align-items-center card-option ${selectedClass(
              "b"
            )}`}
            style={{ borderColor: "#000" }}
            onClick={() => {
              onSelect("b");
            }}
          >
            <Avatar
              sx={{ width: 30, height: 30 }}
              className="mr-2 avatar-option"
            >
              {" "}
              B{" "}
            </Avatar>
            {choices.choice.type_b == "text" ? (
              choices.choice.option_b
            ) : (
              <img
                src={choices.choice.option_b}
                style={{ height: "80px", marginLeft: "20px" }}
              />
            )}
          </Card>
        </Grid>

        {choices.choice.option_c ? (
          <Grid item xs={12}>
            <Card
              variant="outlined"
              fullWidth
              className={`p-2 cursor-pointer d-flex align-items-center card-option ${selectedClass(
                "c"
              )}`}
              style={{ borderColor: "#000" }}
              onClick={() => {
                onSelect("c");
              }}
            >
              <Avatar sx={{ width: 30, height: 30 }} className="mr-2">
                {" "}
                C
              </Avatar>{" "}
              {choices.choice.type_c == "text" ? (
                choices.choice.option_c
              ) : (
                <img
                  src={choices.choice.option_c}
                  style={{ height: "80px", marginLeft: "20px" }}
                />
              )}
            </Card>
          </Grid>
        ) : null}

        {choices.choice.option_d ? (
          <Grid item xs={12}>
            <Card
              variant="outlined"
              fullWidth
              className={`p-2 cursor-pointer d-flex align-items-center card-option ${selectedClass(
                "d"
              )}`}
              style={{ borderColor: "#000" }}
              onClick={() => {
                onSelect("d");
              }}
            >
              <Avatar sx={{ width: 30, height: 30 }} className="mr-2">
                {" "}
                D{" "}
              </Avatar>
              {choices.choice.type_d == "text" ? (
                choices.choice.option_d
              ) : (
                <img
                  src={choices.choice.option_d}
                  style={{ height: "80px", marginLeft: "20px" }}
                />
              )}
            </Card>
          </Grid>
        ) : null}

        {choices.choice.option_e ? (
          <Grid item xs={12}>
            <Card
              variant="outlined"
              fullWidth
              className={`p-2 cursor-pointer d-flex align-items-center card-option ${selectedClass(
                "e"
              )}`}
              style={{ borderColor: "#000" }}
              onClick={() => {
                onSelect("e");
              }}
            >
              <Avatar sx={{ width: 30, height: 30 }} className="mr-2">
                {" "}
                E{" "}
              </Avatar>
              {choices.choice.type_e == "text" ? (
                choices.choice.option_e
              ) : (
                <img
                  src={choices.choice.option_e}
                  style={{ height: "80px", marginLeft: "20px" }}
                />
              )}
            </Card>
          </Grid>
        ) : null}

        <Grid item xs={12} className="mt-4">
          <Button
            variant="contained"
            color="success"
            fullWidth
            className="p-2"
            disabled={!submitButtonActive}
            onClick={onSubmit}
          >
            Jawab
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default OptionText;
