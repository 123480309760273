import { createContext, useState } from "react"

const AuthContext = createContext(null)

const AuthProvider = ({ children }) => {
    const [dataUser, setDataUser] = useState(null)

    return <AuthContext.Provider
        value={{
            dataUser,
            setDataUser
        }} >
        {children}
    </AuthContext.Provider>
}

export { AuthContext, AuthProvider }