import Api from "./Api"


const LoginService =  ({data,onSuccess,onFail}) => {
    return Api.post("login",data).then(res => {
        onSuccess(res)
    }).catch(err => {
        onFail(err.data)
    })
}

export default LoginService