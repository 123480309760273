import  Logo  from "../../assets/logo.png"
import {  Card,   Grid,  Stack, } from "@mui/material"
import AccountMenu from "./AccountMenu";

const Header = () => {
    return (
        <Card variant="outlined" className="p-3">
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs="auto">
                            <img 
                                src={Logo} 
                                style={{ width: "40px" }}
                                alt="Family Education Center" />
                        </Grid>
                        <Grid item xs="auto">
                            <h6 className="mt-2">
                                Family Education Center <br /> Makassar
                            </h6>
                        </Grid>
                    </Grid>
                </div>

                <div >
                    <AccountMenu />
                </div>
            </Stack>

        
        </Card>
    )
}

export default Header