import { Grid, Paper, Container, Button } from "@mui/material"
import { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { AuthContext } from "../../context/AuthContext";
import CategoryService from "../../service/CategoryService";
import Header from "../question/Header";

const Result = (props) => {
    
    let params = useParams();
    let navigate = useNavigate();
    const [category, setCategory] = useState(false)
    const {dataUser} = useContext(AuthContext)
    const onStartAgain = () => {
        
        if(params.type == "column"){
            navigate(`/question-column/${params.id}`)
        }

        if(params.type == "text"){
            navigate(`/question-text/${params.id}`)
        }
        
    }

    const selectCategory = () => {
        navigate("/")
    }

    const getData = (id) => {
        setCategory(false)
        CategoryService.getById({
            token : dataUser? dataUser.token : null,
            id: params.id,
            onSuccess: (res) => {
                setCategory(res.data.result)
            }, onFail: (err) => {
                console.log(err)
            }
        })
    }

    useEffect(() => {
        getData()
    }, [])


    return (
        <Fragment>
            <Header timer={0} category={category} />
            <Container maxWidth="xl" className="mt-5 p-5 d-flex flex-column  align-items-center">
                <h2>JAWABAN BENAR </h2>
                <p style={{ fontSize: "100px", fontWeight: "bold" }} className="text-primary"> {params.correct}</p>
                <Container className="mt-5 d-flex justify-content-center">
                    <Button variant="contained" color="error" className="" style={{ width: "200px" }} onClick={selectCategory}>
                        PILIH KATEGORI
                    </Button>
                    <Button variant="contained" color="success" className="ml-5" style={{ width: "200px" }} onClick={onStartAgain}>
                        MULAI LAGI
                    </Button>
                </Container>
            </Container>
        </Fragment>
    )
}

export default Result