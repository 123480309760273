import axios from "axios"

const baseUrl = "https://admin.fecmks.com/api"



const headers = (token) => {

    return token ? {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
    } : {
        "Content-Type": "application/json",
    }
}



export const Post = (path, params,  token ) => {
    const url = `${baseUrl}/${path}`
    const promise = new Promise((resolve, reject) => {
        axios({
            method: "post",
            url: url,
            params: params,
            headers: { ...headers(token) },

        }).then(response => {
            resolve(response)
        }).catch(err => {
            if (err.response) {
                reject(err.response)
            } else if (err.request) {
                reject(err.request)
            } else {
                reject(err)
            }
        })
    })
    return promise
}

export const Get = (path, params, token ) => {
    const url = `${baseUrl}/${path}`
    const promise = new Promise((resolve, reject) => {
        axios({
            method: "get",
            url: url,
            params: params,
            headers: { ...headers(token) },
        }).then(response => {
            resolve(response)
        }).catch(err => {

            if (err.response) {
                reject(err.response)
                if (err.response.status == 401) {
                    // Redirect them to the /login page, but save the current location they were
                    // trying to go to when they were redirected. This allows us to send them
                    // along to that page after they login, which is a nicer user experience
                    // than dropping them off on the home page.
                    // localStorage.removeItem("dataUser");
                    window.location.href = "/login"
                }
            } else if (err.request) {
                reject(err.request)
            } else {
                reject(err)
            }
        })
    })
    return promise
}

const Api = {
    post: Post,
    get: Get
}
export default Api