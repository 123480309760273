import Api from "./Api"


const Get = ({ params, onSuccess, onFail,token }) => {
    return Api.get("category",params,token)
        .then(res => {
            onSuccess(res)
        }).catch(err => {
            onFail(err)
        })
}

const GetById = ({ id, onSuccess, onFail,token }) => {
    return Api.get(`category/${id}`,"",token)
        .then(res => {
            onSuccess(res)
        }).catch(err => {
            onFail(err)
        })
}

const CategoryService = {
    get : Get,
    getById : GetById
}
export default CategoryService