import { toast } from "react-toastify"


const CustomToast = (type,description) => {
    switch (type) {
        case "error":
            return toast.error(description);
        case "success":
            return toast.success(description);
        default:
            return toast.info(description);

    }
}

export default CustomToast