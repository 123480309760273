import { Grid, Skeleton } from "@mui/material"


const LoadingCard = () => {
    return (
        <Grid container spacing={1} className="mt-2">
            {[...Array(4)].map((x, i) =>
                <Grid item xs={6} md={3}>
                    <Skeleton width={350} height={510} style={{ marginTop: "-110px" }} />
                </Grid>
            )}
        </Grid>)
}

export default LoadingCard