import { Fragment, useContext, useState } from "react"
import { Card, CardContent, Button, Grid, TextField, CircularProgress } from '@mui/material';
import   Logo  from '../../assets/logo.png';
import "./Login.css"

import CustomToast from "../../components/custom-toast";
import LoginService from "../../service/LoginService";
import {AuthContext} from "../../context/AuthContext"
import { useNavigate } from "react-router"

const Login = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [username, setUsername] = useState(false)
    const [password, setPassword] = useState(false)

    const { setDataUser } = useContext(AuthContext)

    const onSubmit = async () => {

        setLoading(true)

        await LoginService({
            data: {
                username: username,
                password: password
            },
            onSuccess: (res) => {
                CustomToast("success",`Selamat Datang ${res.data.name}`)
                let dataUser = {
                    "name": res.data.name,
                    "role": res.data.role,
                    "login_at" : new Date(),
                    "token": res.data.token,
                }
                console.log(dataUser)
                setDataUser(dataUser)
                navigate("/")
                
            }, onFail: (err) => {
                CustomToast("error", err.errors)
                setLoading(false)
            }
        })

    }

    return (
        <Fragment>
            <Card className="card-login" >

                <CardContent className="card-content-login">

                    <img
                        src={Logo}
                        loading="lazy"
                        alt="Family Education Center"
                    />

                    <Grid container className="mt-5">
                        <Grid item md={12}>
                            <TextField label="Username" variant="outlined" size="small" fullWidth onKeyUp={(e) => { setUsername(e.target.value) }} />
                        </Grid>
                    </Grid>

                    <Grid container className="mt-4">
                        <Grid item md>
                            <TextField type="password" label="Password" variant="outlined" size="small" fullWidth onKeyUp={(e) => { setPassword(e.target.value) }} />
                        </Grid>
                    </Grid>

                    <Button disabled={loading || username === false || password === false} variant="contained" color="primary" className="mt-4" fullWidth
                        onClick={
                            onSubmit
                        }
                    >
                        {loading ? <CircularProgress size={28} color="primary" /> : "Login"}
                    </Button>

                    <div className="text-center mt-4">
                        <small>&copy; 2021 Family Education Center </small>
                    </div>
                </CardContent>

            </Card>
        </Fragment>
    )
}

export default Login