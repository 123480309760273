import { Dialog, DialogActions, DialogContent, DialogTitle,Button, DialogContentText } from "@mui/material"
import { useNavigate } from "react-router"


const DialogCancel = (props) => {

    const {
        open,
        onCancel,
        urlOut
    } = props
    
    let navigate = useNavigate();
    const handleClose = () => {
        onCancel(false)
    }

    const handleOk = () => {
        navigate(urlOut);
    }

    return (
        <Dialog
            open={open}
            maxWidth="xs"
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{"Apakah Anda Yakin?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Semua jawaban akan di hapus dan akan kembali Kehalaman kategori.
                </DialogContentText>
            </DialogContent>
            <DialogActions className="d-flex justify-content-between px-3">
                <Button onClick={handleClose}>Batal</Button>
                <Button color="error" onClick={handleOk}>YA</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogCancel