import { Fragment, useContext, useEffect, useState } from "react"
import { Container, FormControl, Tabs, InputAdornment, InputLabel, OutlinedInput, Stack, Tab, Grid, Box, Skeleton, } from "@mui/material"

import Header from "./Header";
import SearchIcon from '@mui/icons-material/Search';
import CardList from "./CardList";
import CategoryService from "../../service/CategoryService";
import LoadingCard from "./LoadingCard";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import CategoryGroupService from "../../service/CategoryGroupService";
import { AuthContext } from "../../context/AuthContext";

const Category = () => {

    const [categories, setCategory] = useState(false)
    const [groups, setGroups] = useState(false)
    const [groupSelected, setGroupSelected] = useState(0)
    const [searchTerm, setSearchTerm] = useState(0)
    const {dataUser} = useContext(AuthContext)

    const getData = (params) => {
        setCategory(false)
        CategoryService.get({
            token : dataUser? dataUser.token : null, 
            params : params,
            onSuccess: (res) => {
                setCategory(res.data.result)
            }, onFail: (err) => {
                console.log(err)
            }
        })
    }

    const getDataGroup = () => {
        CategoryGroupService({
            token : dataUser? dataUser.token : null,
            onSuccess: (res) => {
                setGroups(res.data)
            }, onFail: (err) => {
                console.log(err)
            }
        })
    }

    useEffect(() => {

        getData()
    }, [])

    useEffect(() => {
        getDataGroup()
    }, [])

    const onTap = tab => {
        if(tab != groupSelected){
            setGroupSelected(tab); 
            getData({group : tab})
        }   
    }

    const onTapAll = () => {
        if(groupSelected !== 0){
            setGroupSelected(0); 
            getData()
        }
    }

    return (
        <Fragment>
            <Container maxWidth="xl" className="mt-3">
                <Header />
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className="mt-4 mb-1"
                >
                    <div className="pl-2">
                        <h3>Kategori</h3>
                    </div>
                    <div>

                        {groups && <Box sx={{ maxWidth: 560, bgcolor: 'background.paper', borderRadius: "5px" }}>

                            <Tabs
                                value={groupSelected}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab label="Semua Kategori" value={0}  onClick={() => {onTapAll()}}/>
                                {groups.map((data, i) => (
                                    <Tab key={i} label={data.name} value={data.id} onClick={() => {onTap(data.id)}} />
                                ))}




                            </Tabs>

                        </Box>
                        }
                        {!groups && <Skeleton width={550} height={70} />}


                    </div>
                    <div className="pr-3 ">
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <InputLabel htmlFor="outlined-adornment-amount">Cari..</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-amount"
                                size="small"
                                startAdornment={<InputAdornment position="start"> <SearchIcon /></InputAdornment>}
                                label="Amount"
                            onKeyUp={(e) => { setSearchTerm(e.target.value)} }
                            />
                        </FormControl>
                    </div>
                </Stack>


                {categories &&
                    <PerfectScrollbar>
                        <Grid container spacing={1} className="mt-2" style={{ maxHeight: "530px" }}>

                            {categories.filter((val) => {
                                if(searchTerm == ""){
                                    return val
                                }else if(val.name.toLowerCase().includes(searchTerm.toLowerCase())){
                                    return val
                                }
                            }).map((data, i) => (
                                <Grid key={i} item xs={6} md={3} className={(i > 3 ? "mt-3" : "")}>
                                    <CardList data={data} />
                                </Grid>
                            ))}

                        </Grid>
                    </PerfectScrollbar>

                }

                {!categories && <LoadingCard />}

            </Container>
        </Fragment >
    )
}

export default Category

