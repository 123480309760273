import { Grid, Paper, Button } from "@mui/material"
import PerfectScrollbar from 'react-perfect-scrollbar'

const NumberQuestion = (props) => {

    const {
        questions,
        numberActive,
        onChangeQuestion
    } = props
    
    const colorButton = (number) => {
        if (number == numberActive) {
            return "primary"
        } else {
            return "neutral"

        }
    }

    return (
        <Paper className="p-3 " >
            <PerfectScrollbar style={{ maxHeight: "600px" }}>
                <Grid container spacing={2} className="d-flex justify-content-between">
                    {questions && questions.map((data, i) =>
                        <Grid item xs="auto" key={i}>
                            <Button size="small" variant="contained" color={data.answer ? "success" : colorButton(data.number)} onClick={() => {onChangeQuestion(data.number) }}>
                                {data.number}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </PerfectScrollbar>
        </Paper>
    )
}

export default NumberQuestion